import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const clientPaymentApi = createApi({
    reducerPath: "clientPaymentApi",
    baseQuery,
    endpoints: (builder) => ({
        getClientPaymentList: builder.query({
            query: ({ page, limit, clients, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (clients.length > 0) {
                    queryParams.append('client_ids', JSON.stringify(clients));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/client-payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["client-payment"],
            transformResponse: (response) => response,
        }),
        getAllClientPaymentList: builder.mutation({
            query: ({ page, limit, clients, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (clients.length > 0) {
                    queryParams.append('client_ids', JSON.stringify(clients));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/client-payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["client-payment"],
            transformResponse: (response) => response,
        }),
        markAsPaidClient: builder.mutation({
            query: (body) => ({
                url: `main/v1/client-payments/mark-as-paid`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["client", "client-payment"],
        }),
        getInvoiceSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/client-payments/data`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            transformResponse: (response) => response,
            providesTags: ["client-payment"],
        }),
        createInvoiceSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/client-payments/new`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["client", "client-payment"],
        }),
        getInvoiceByID: builder.query({
            query: (id) => ({
                url: `main/v1/client-payments/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["client", "client-payment"],
        }),
        editInvoiceSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/client-payments/edit`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["client", "client-payment"],
        }),
    }),
});

export const { useGetClientPaymentListQuery, useMarkAsPaidClientMutation, useGetInvoiceSlipMutation, useCreateInvoiceSlipMutation, useGetInvoiceByIDQuery, useEditInvoiceSlipMutation, useGetAllClientPaymentListMutation } = clientPaymentApi;
