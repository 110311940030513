import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const subContractorPaymentApi = createApi({
    reducerPath: "subContractorPaymentApi",
    baseQuery,
    endpoints: (builder) => ({
        getSubContractorPaymentList: builder.query({
            query: ({ page, limit, subContractors, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (subContractors.length > 0) {
                    queryParams.append('subcontractor_ids', JSON.stringify(subContractors));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/subcontractor-payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["subContractor-payment"],
            transformResponse: (response) => response,
        }),
        getAllSubContractorPaymentList: builder.mutation({
            query: ({ page, limit, subContractors, startDate, endDate, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (subContractors.length > 0) {
                    queryParams.append('subcontractor_ids', JSON.stringify(subContractors));
                }

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `main/v1/subcontractor-payments?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            providesTags: ["subContractor-payment"],
            transformResponse: (response) => response,
        }),
        subContractorMarkAsPaid: builder.mutation({
            query: (body) => ({
                url: `main/v1/subcontractor-payments/mark-as-paid`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["subContractor-payment"],
        }),
        subContractorSettlementSlipData: builder.mutation({
            query: (body) => ({
                url: `main/v1/subcontractor-payments/data`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            transformResponse: (response) => response,
            providesTags: ["subcontractor"],
        }),
        createSubContractorBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/subcontractor-payments/new`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["subcontractor", "subContractor-payment"],
        }),
        getSubContractorSlipByID: builder.query({
            query: (id) => ({
                url: `main/v1/subcontractor-payments/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["subcontractor", "subContractor-payment"],
        }),
        editSubContractorBillsettlementSlip: builder.mutation({
            query: (body) => ({
                url: `main/v1/subcontractor-payments/edit`,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body
            }),
            invalidatesTags: ["subContractor-payment"],
        }),
    }),
});

export const { useGetSubContractorPaymentListQuery,useCreateSubContractorBillsettlementSlipMutation,useEditSubContractorBillsettlementSlipMutation,useGetAllSubContractorPaymentListMutation,useGetSubContractorSlipByIDQuery,useSubContractorMarkAsPaidMutation,useSubContractorSettlementSlipDataMutation } = subContractorPaymentApi;
