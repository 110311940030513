import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { hasPermission } from 'src/utils/permissions';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/coming-soon'));

// Activity Logs
const PageActivityLog = lazy(() => import('src/pages/dashboard/activityLogs'))

// Staff
const StaffPage = lazy(() => import('src/pages/dashboard/staff/staff_list'));
const CreateStaffPage = lazy(() => import('src/pages/dashboard/staff/staff_new'));
const EditStaffPage = lazy(() => import('src/pages/dashboard/staff/staff-edit'));
const AttendancePage = lazy(() => import('src/pages/dashboard/staff/attendance_list'));
const SalaryPage = lazy(() => import('src/pages/dashboard/staff/salary_list'));
const CreateSalarySlipPage = lazy(() => import('src/pages/dashboard/staff/salary_slip_new'));
const EditSalarySlipPage = lazy(() => import('src/pages/dashboard/staff/salary_slip_edit'));
const ViewSalarySlipPage = lazy(() => import('src/pages/dashboard/staff/salary_slip_view'));

// ROLE
const RolesPage = lazy(() => import('src/pages/dashboard/role/list'));
const NewRolePage = lazy(() => import('src/pages/dashboard/role/new'));
const EditRolePage = lazy(() => import('src/pages/dashboard/role/edit'));

// SHOPS
const ShopsPage = lazy(() => import('src/pages/dashboard/shops/shop_list'));
const CreateShopPage = lazy(() => import('src/pages/dashboard/shops/shop_new'));
const EditShopPage = lazy(() => import('src/pages/dashboard/shops/shop_edit'));
const PurchaseHistoryPage = lazy(() => import('src/pages/dashboard/shops/purchase_history_list'));
const PaymentHistoryPage = lazy(() => import('src/pages/dashboard/shops/payment_history_list'));
const CreateBillSettlementSlipPage = lazy(() => import('src/pages/dashboard/shops/bill_settlement_slip_new'));
const EditBillSettlementSlipPage = lazy(() => import('src/pages/dashboard/shops/bill_settlement_slip_edit'));
const ViewBillSettlementSlipPage = lazy(() => import('src/pages/dashboard/shops/bill_settlement_slip_view'));

// ----------------------------------------------------------------------
// USER
const CreateNonStaffUserPage = lazy(() => import('src/pages/dashboard/user/newNonstaff'));
const CreateUserPage = lazy(() => import('src/pages/dashboard/user/new'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const EditUserPage = lazy(() => import('src/pages/dashboard/user/edit'));

// SITE
const CreateSitePage = lazy(() => import('src/pages/dashboard/sites/site_new'));
const SiteListPage = lazy(() => import('src/pages/dashboard/sites/site_list'));
const SiteEditPage = lazy(() => import('src/pages/dashboard/sites/site_edit'));

// Client
const ClientListPage = lazy(() => import('src/pages/dashboard/client/list'));
const CreateClientPage = lazy(() => import('src/pages/dashboard/client/new'));
const EditClientPage = lazy(() => import('src/pages/dashboard/client/edit'));
const ClientPaymentHistoryPage = lazy(() => import('src/pages/dashboard/client/client_payment_history_list'));
const CreateClientInvoicePaymentPage = lazy(() => import('src/pages/dashboard/client/client_invoice_payment_new'));
const EditClientInvoicePaymentPage = lazy(() => import('src/pages/dashboard/client/client_invoice_payment_edit'));
const ViewClientInvoicePaymentPage = lazy(() => import('src/pages/dashboard/client/client_invoice_payment_view'));

// Subcontractor
const SubcontractorListPage = lazy(() => import('src/pages/dashboard/subContractor/list'));
const SubcontractorNewPage = lazy(() => import('src/pages/dashboard/subContractor/new'));
const SubcontractorEditPage = lazy(() => import('src/pages/dashboard/subContractor/edit'));
const SubcontractorPaymentHistoryPage = lazy(() => import('src/pages/dashboard/subContractor/sub-contractor-payment-history-list'));
const CreateSubcontractorPaymentPage = lazy(() => import('src/pages/dashboard/subContractor/sub-contractor-bill-settlement-slip-new'));
const EditSubcontractorPaymentPage = lazy(() => import('src/pages/dashboard/subContractor/sub-contractor-bill-settlement-slip-edit'));
const ViewSubcontractorPaymentPage = lazy(() => import('src/pages/dashboard/subContractor/sub-contractor-bill-settlement-slip-view'));

// Labourer
const LabourerPage = lazy(() => import('src/pages/dashboard/labourer/labourer_list'));
const CreateLabourerPage = lazy(() => import('src/pages/dashboard/labourer/labourer_new'));
const WorkHistoryPage = lazy(() => import('src/pages/dashboard/labourer/work_history_list'));
const EditLabourerPage = lazy(() => import('src/pages/dashboard/labourer/labourer_edit'));
const TeamsPage = lazy(() => import('src/pages/dashboard/labourer/team_list'));
const TeamCreatePage = lazy(() => import('src/pages/dashboard/labourer/team_new'));
const TeamEditPage = lazy(() => import('src/pages/dashboard/labourer/team_edit'));
const LabourerPaymentHistoryPage = lazy(() => import('src/pages/dashboard/labourer/payment_history_list'));
const CreatePaymentSlipPage = lazy(() => import('src/pages/dashboard/labourer/payment_slip_new'));
const EditPaymentSlipPage = lazy(() => import('src/pages/dashboard/labourer/payment_slip_edit'));
const ViewPaymentSlipPage = lazy(() => import('src/pages/dashboard/labourer/payment_slip_view'));
const AssignmentListPage = lazy(() => import('src/pages/dashboard/labourer/assignment_list'));
const AssignmentCreatePage = lazy(() => import('src/pages/dashboard/labourer/assignment_new'));
const AssignmentEditPage = lazy(() => import('src/pages/dashboard/labourer/assignment_edit'));

// Utils
const UtilsPage = lazy(() => import('src/pages/dashboard/utils/list'))
const UnitsPage = lazy(() => import('src/pages/dashboard/utils/units-list'))
const MaterialsPage = lazy(() => import('src/pages/dashboard/utils/materials-list'))

const TaskTypesPage = lazy(() => import('src/pages/dashboard/utils/task-types-list'))


export function dashboardRoutes(user) {
  return [
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [

        { element: <IndexPage />, index: true },

        hasPermission(user, 'Activity Log', 'Read') && { path: 'activitylogs', element: <PageActivityLog /> },

        hasPermission(user, 'Roles', 'Read') && {
          path: 'roles',
          children: [
            { element: <RolesPage />, index: true },
            hasPermission(user, 'Roles', 'Create') && { path: 'new', element: <NewRolePage /> },
            { path: 'edit', element: <EditRolePage /> },
          ],
        },

        hasPermission(user, 'Manage Staff', 'Read') && {
          path: 'staff',
          children: [
            { element: <StaffPage />, index: true },
            hasPermission(user, 'Manage Staff', 'Create') && { path: 'create', element: <CreateStaffPage /> },
            { path: 'edit', element: <EditStaffPage /> },
            hasPermission(user, 'Staff Attendance', 'Read') && { path: 'attendance', element: <AttendancePage /> },
            hasPermission(user, 'Staff Salary', 'Read') && { path: 'salary', element: <SalaryPage /> },
            hasPermission(user, 'Staff Salary', 'Create') && { path: 'salaryslip/new', element: <CreateSalarySlipPage /> },
            hasPermission(user, 'Staff Salary', 'Update') && { path: 'salaryslip/edit', element: <EditSalarySlipPage /> },
            hasPermission(user, 'Staff Salary', 'Read') && { path: 'salaryslip', element: <ViewSalarySlipPage /> },
          ],
        },

        hasPermission(user, 'Shops', 'Read') && {
          path: 'shops',
          children: [
            { element: <ShopsPage />, index: true },
            hasPermission(user, 'Shops', 'Create') && { path: 'create', element: <CreateShopPage /> },
            { path: 'edit', element: <EditShopPage /> },
            hasPermission(user, 'Shops Purchase History', 'Read') && { path: 'purchasehistory', element: <PurchaseHistoryPage /> },
            hasPermission(user, 'Shops Payments', 'Read') && { path: 'paymenthistory', element: <PaymentHistoryPage /> },
            hasPermission(user, 'Shops Payments', 'Create') && { path: 'billsettlementslip/new', element: <CreateBillSettlementSlipPage /> },
            hasPermission(user, 'Shops Payments', 'Update') && { path: 'billsettlementslip/edit', element: <EditBillSettlementSlipPage /> },
            hasPermission(user, 'Shops Payments', 'Read') && { path: 'billsettlementslip', element: <ViewBillSettlementSlipPage /> },
          ],
        },

        hasPermission(user, 'Users', 'Read') && {
          path: 'user',
          children: [
            { element: <UserListPage />, index: true },
            hasPermission(user, 'Users', 'Create') && { path: 'new', element: <CreateUserPage /> },
            hasPermission(user, 'Users', 'Create') && { path: 'newNonStaff', element: <CreateNonStaffUserPage /> },
            { path: 'edit', element: <EditUserPage /> },
          ],
        },

        hasPermission(user, 'Sites', 'Read') && {
          path: 'sites',
          children: [
            { element: <SiteListPage />, index: true },
            hasPermission(user, 'Sites', 'Create') && { path: 'new', element: <CreateSitePage /> },
            { path: 'edit', element: <SiteEditPage /> },
          ],
        },

        hasPermission(user, 'Clients', 'Read') && {
          path: 'client',
          children: [
            { element: <ClientListPage />, index: true },
            hasPermission(user, 'Clients', 'Create') && { path: 'new', element: <CreateClientPage /> },
            { path: 'edit', element: <EditClientPage /> },
            hasPermission(user, 'Client Payments', 'Read') && { path: 'paymenthistory', element: <ClientPaymentHistoryPage /> },
            hasPermission(user, 'Client Payments', 'Create') && { path: 'clientPaymentInvoice/new', element: <CreateClientInvoicePaymentPage /> },
            hasPermission(user, 'Client Payments', 'Update') && { path: 'clientPaymentInvoice/edit', element: <EditClientInvoicePaymentPage /> },
            hasPermission(user, 'Client Payments', 'Read') && { path: 'clientPaymentInvoice', element: <ViewClientInvoicePaymentPage /> },
          ],
        },

        hasPermission(user, 'Subcontractors', 'Read') && {
          path: 'subcontractor',
          children: [
            { element: <SubcontractorListPage />, index: true },
            hasPermission(user, 'Subcontractors', 'Create') && { path: 'new', element: <SubcontractorNewPage /> },
            { path: 'edit', element: <SubcontractorEditPage /> },
            hasPermission(user, 'Subcontractor Payments', 'Read') && { path: 'paymenthistory', element: <SubcontractorPaymentHistoryPage /> },
            hasPermission(user, 'Subcontractor Payments', 'Create') && { path: 'subContractorPayment/new', element: <CreateSubcontractorPaymentPage /> },
            hasPermission(user, 'Subcontractor Payments', 'Update') && { path: 'subContractorPayment/edit', element: <EditSubcontractorPaymentPage /> },
            hasPermission(user, 'Subcontractor Payments', 'Read') && { path: 'subContractorPayment', element: <ViewSubcontractorPaymentPage /> },
          ].filter(Boolean),
        },

        hasPermission(user, 'Laborers', 'Read') && {
          path: 'labourer',
          children: [
            { element: <LabourerPage />, index: true },
            hasPermission(user, 'Laborers', 'Create') && { path: 'create', element: <CreateLabourerPage /> },
            hasPermission(user, 'Laborers Work History', 'Read') && { path: 'workhistory', element: <WorkHistoryPage /> },
            { path: 'edit', element: <EditLabourerPage /> },
            hasPermission(user, 'Laborers Teams', 'Read') && { path: 'teams', element: <TeamsPage /> },
            hasPermission(user, 'Laborers Teams', 'Create') && { path: 'teams/create', element: <TeamCreatePage /> },
            { path: 'teams/edit', element: <TeamEditPage /> },
            hasPermission(user, 'Laborers Payments', 'Read') && { path: 'payments', element: <LabourerPaymentHistoryPage /> },
            hasPermission(user, 'Laborers Payments', 'Create') && { path: 'paymentslip/new', element: <CreatePaymentSlipPage /> },
            hasPermission(user, 'Laborers Payments', 'Update') && { path: 'paymentslip/edit', element: <EditPaymentSlipPage /> },
            hasPermission(user, 'Laborers Payments', 'Read') && { path: 'paymentslip', element: <ViewPaymentSlipPage /> },
            hasPermission(user, 'Laborers Assignments', 'Read') && { path: 'assignments', element: <AssignmentListPage /> },
            hasPermission(user, 'Laborers Assignments', 'Create') && { path: 'assignments/create', element: <AssignmentCreatePage /> },
            { path: 'assignments/edit', element: <AssignmentEditPage /> },
          ],
        },

        {
          path: 'utils',
          children: [
            hasPermission(user, 'Values', 'Read') && { path: 'values', element: <UtilsPage /> },
            hasPermission(user, 'Units', 'Read') && { path: 'units', element: <UnitsPage /> },
            hasPermission(user, 'Materials', 'Read') && { path: 'materials', element: <MaterialsPage /> },
            hasPermission(user, 'Task Types', 'Read') && { path: 'tasktype', element: <TaskTypesPage /> },
          ]
        },

      ],
    },
  ];
}
