// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    otp: `${ROOTS.AUTH}/otp`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    roles: {
      root: `${ROOTS.DASHBOARD}/roles`,
      new: `${ROOTS.DASHBOARD}/roles/new`,
      edit: `${ROOTS.DASHBOARD}/roles/edit`,
    },
    activityLogs: `${ROOTS.DASHBOARD}/activitylogs`,
    staff: {
      root: `${ROOTS.DASHBOARD}/staff`,
      createStaff: `${ROOTS.DASHBOARD}/staff/create`,
      editStaff: `${ROOTS.DASHBOARD}/staff/edit`,
      attendance: `${ROOTS.DASHBOARD}/staff/attendance`,
      salary: `${ROOTS.DASHBOARD}/staff/salary`,
      salarySlipNew: `${ROOTS.DASHBOARD}/staff/salaryslip/new`,
      salarySlipEdit: `${ROOTS.DASHBOARD}/staff/salaryslip/edit`,
      salarySlipView: `${ROOTS.DASHBOARD}/staff/salaryslip`,
    },
    Shops: {
      root: `${ROOTS.DASHBOARD}/shops`,
      createShop: `${ROOTS.DASHBOARD}/shops/create`,
      edit: `${ROOTS.DASHBOARD}/shops/edit`,
      purchaseHistory: `${ROOTS.DASHBOARD}/shops/purchasehistory`,
      paymentHistory: `${ROOTS.DASHBOARD}/shops/paymenthistory`,
      billSettlementNew: `${ROOTS.DASHBOARD}/shops/billsettlementslip/new`,
      billSettlementEdit: `${ROOTS.DASHBOARD}/shops/billsettlementslip/edit`,
      billSettlementView: `${ROOTS.DASHBOARD}/shops/billsettlementslip`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      createNewStaff: `${ROOTS.DASHBOARD}/user/new`,
      createNewNonStaff: `${ROOTS.DASHBOARD}/user/newNonStaff`,
      editUser: `${ROOTS.DASHBOARD}/user/edit`,
    },
    sites: {
      root: `${ROOTS.DASHBOARD}/sites`,
      new: `${ROOTS.DASHBOARD}/sites/new`,
      edit: `${ROOTS.DASHBOARD}/sites/edit`,
    },
    client: {
      root: `${ROOTS.DASHBOARD}/client`,
      createNewClient: `${ROOTS.DASHBOARD}/client/new`,
      editClient: `${ROOTS.DASHBOARD}/client/edit`,
      paymentHistory: `${ROOTS.DASHBOARD}/client/paymenthistory`,
      clientPaymentInvoiceNew: `${ROOTS.DASHBOARD}/client/clientPaymentInvoice/new`,
      clientPaymentInvoiceEdit: `${ROOTS.DASHBOARD}/client/clientPaymentInvoice/edit`,
      clientPaymentInvoiceView: `${ROOTS.DASHBOARD}/client/clientPaymentInvoice`,
    },
    subcontractor: {
      root: `${ROOTS.DASHBOARD}/subcontractor`,
      createNewSubcontractor: `${ROOTS.DASHBOARD}/subcontractor/new`,
      editSubcontractor: `${ROOTS.DASHBOARD}/subcontractor/edit`,
      paymentHistory: `${ROOTS.DASHBOARD}/subcontractor/paymenthistory`,
      subcontractorPaymentSlipNew: `${ROOTS.DASHBOARD}/subcontractor/subContractorPayment/new`,
      subcontractorPaymentSlipEdit: `${ROOTS.DASHBOARD}/subcontractor/subContractorPayment/edit`,
      subcontractorPaymentSlipView: `${ROOTS.DASHBOARD}/subcontractor/subContractorPayment`,
    },
    labourer: {
      root: `${ROOTS.DASHBOARD}/labourer`,
      createLabour: `${ROOTS.DASHBOARD}/labourer/create`,
      workhistory: `${ROOTS.DASHBOARD}/labourer/workhistory`,
      editLabourer: `${ROOTS.DASHBOARD}/labourer/edit`,
      teams: `${ROOTS.DASHBOARD}/labourer/teams`,
      createTeam: `${ROOTS.DASHBOARD}/labourer/teams/create`,
      paymentHistory: `${ROOTS.DASHBOARD}/labourer/payments`,
      PaymentSlipNew: `${ROOTS.DASHBOARD}/labourer/paymentslip/new`,
      PaymentSlipEdit: `${ROOTS.DASHBOARD}/labourer/paymentslip/edit`,
      PaymentSlipView: `${ROOTS.DASHBOARD}/labourer/paymentslip`,
      editTeam: `${ROOTS.DASHBOARD}/labourer/teams/edit`,
      assignments: `${ROOTS.DASHBOARD}/labourer/assignments`,
      createAssignment: `${ROOTS.DASHBOARD}/labourer/assignments/create`,
      editAssignment: `${ROOTS.DASHBOARD}/labourer/assignments/edit`,
    },
    utils: {
      root: `${ROOTS.DASHBOARD}/utils`,
      values: `${ROOTS.DASHBOARD}/utils/values`,
      units: `${ROOTS.DASHBOARD}/utils/units`,
      taskTypes: `${ROOTS.DASHBOARD}/utils/tasktype`,
      materials: `${ROOTS.DASHBOARD}/utils/materials`,
    }
  },
};
