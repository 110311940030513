import { configureStore, createSlice } from "@reduxjs/toolkit";
import { authApi } from "./auth/auth-api";
import { userApi } from "./user/user-api";
import { dataApi } from "./data/data-api";
import { roleApi } from "./role/role-api";
import { staffApi } from "./staff/staff-api";
import { siteApi } from "./sites/site-api";
import { activityLogsApi } from "./activity-logs/activity-logs-api";
import { clientApi } from "./client/client-api";
import { shopsApi } from "./shops/shops-api";
import { purchaseApi } from "./shops/purchase-api";
import { staffSalaryApi } from "./staff/staff-salary-api";
import { labourerApi } from "./labourer/labourer-api";
import { teamsApi } from "./labourer/team-api";
import { paymentApi } from "./shops/payment-api";
import { utilsApi } from "./utils/utils-api";
import { assignmentsApi } from "./assignments/assignments-api";
import { labourerPaymentApi } from "./labourer/labourer-payment-api";
import { unitsApi } from "./utils/units-api";
import { subcontractorApi } from "./subContractor/sub-contractor-api";
import { notificationApi } from "./notification/notification-api";
import { materialsApi } from "./utils/materials-api";
import { taskTypesApi } from "./utils/task-types-api";
import { clientPaymentApi } from "./client/client-payment-api";
import { subContractorPaymentApi } from "./subContractor/sub-contractor-payment-api";


const homeSlice = createSlice({
    name: "home",
    initialState: {
        cardView: JSON.parse(localStorage.getItem('cardView')) ?? false
    },
    reducers: {
        setCardView: (state, action) => {
            state.cardView = action.payload;
            localStorage.setItem('cardView', action.payload)
        },
    },
});

const store = configureStore({
    reducer: {
        home: homeSlice.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [dataApi.reducerPath]: dataApi.reducer,
        [roleApi.reducerPath]: roleApi.reducer,
        [staffApi.reducerPath]: staffApi.reducer,
        [siteApi.reducerPath]: siteApi.reducer,
        [activityLogsApi.reducerPath]: activityLogsApi.reducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [shopsApi.reducerPath]: shopsApi.reducer,
        [purchaseApi.reducerPath]: purchaseApi.reducer,
        [staffSalaryApi.reducerPath]: staffSalaryApi.reducer,
        [labourerApi.reducerPath]: labourerApi.reducer,
        [teamsApi.reducerPath]: teamsApi.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
        [utilsApi.reducerPath]: utilsApi.reducer,
        [unitsApi.reducerPath]: unitsApi.reducer,
        [assignmentsApi.reducerPath]: assignmentsApi.reducer,
        [labourerPaymentApi.reducerPath]: labourerPaymentApi.reducer,
        [subcontractorApi.reducerPath]: subcontractorApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [materialsApi.reducerPath]: materialsApi.reducer,
        [taskTypesApi.reducerPath]: taskTypesApi.reducer,
        [clientPaymentApi.reducerPath]: clientPaymentApi.reducer,
        [subContractorPaymentApi.reducerPath]: subContractorPaymentApi.reducer,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(authApi.middleware)
        .concat(userApi.middleware)
        .concat(dataApi.middleware)
        .concat(roleApi.middleware)
        .concat(staffApi.middleware)
        .concat(siteApi.middleware)
        .concat(activityLogsApi.middleware)
        .concat(clientApi.middleware)
        .concat(shopsApi.middleware)
        .concat(purchaseApi.middleware)
        .concat(staffSalaryApi.middleware)
        .concat(labourerApi.middleware)
        .concat(teamsApi.middleware)
        .concat(paymentApi.middleware)
        .concat(utilsApi.middleware)
        .concat(assignmentsApi.middleware)
        .concat(unitsApi.middleware)
        .concat(labourerPaymentApi.middleware)
        .concat(subcontractorApi.middleware)
        .concat(notificationApi.middleware)
        .concat(materialsApi.middleware)
        .concat(notificationApi.middleware)
        .concat(taskTypesApi.middleware)
        .concat(clientPaymentApi.middleware)
        .concat(subContractorPaymentApi.middleware),

});

export const homeActions = homeSlice.actions;

export default store;
